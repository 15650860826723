import React from 'react'

export default function Bio() {
  return (
    <main>
      <p className="intro">
        My passion for all things tech and web development started with a computer built from scraps and a terrible website that I built with HTML and CSS when the internet and I were both very young. I’ve loved code ever since, but without much opportunity for formal education, I taught myself Linux, HTML, CSS and explored PHP and Python with any resources I could find, and I continued working with the technologies that formed the world around me. I was driven into the tech field and moved on to gain the formal training I longed for with Flatiron School, which provided me with the tools to refine my programming skills. Succeeding at Flatiron inspired my career forward to find a team that shares my passions to bring people together through technology.
      </p>
    </main>
  )
}
